import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        margin: 4em auto;
        text-align: center;

        .confirmation-header {
            .headline {
                color: ${variables.dpc_fontDark};
                font-size: 2.6em;
                font-weight: 900;
                margin-bottom: .5em;
            }
            .subheadline {
                color: ${variables.dpc_fontDark};
                font-size: 1.3em;
                font-weight: 400;
            }
        }

        @media (max-width: ${variables.tabletL}) {}
        @media (max-width: ${variables.mobileL}) {}
    `;

