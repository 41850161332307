import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PUBLIC_RESERVATIONS_ONLINE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';

export default class PublicOnlineCheckout extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        response: PropTypes.shape({

        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        const { location, history, response } = this.props;
        const queryObject = parseQueryToObject(location.search);
        setTimeout(() => {
            history.push(withVariables(PUBLIC_RESERVATIONS_ONLINE.path, {}, { ...queryObject, leadId: response.payload.leadId }));
        }, 3000);
    }

    render() {
        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-confirmation': true,
                })}
                styles={require('./styles')}
            >
                <header className="confirmation-header">
                    <h1 className="headline">
                        Dziekujemy!
                    </h1>
                    <h2 className="subheadline">
                        Za chwilę zostaniesz przekierowany do rezerwacji Twojego treningu diagnostycznego
                    </h2>
                </header>
            </StyledComponent>
        );
    }
}
