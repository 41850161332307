import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        min-height: 100vh;
        position: relative;
        background-color: ${variables.dpc_backgroundWhite};

        .survey-header {
            color: ${variables.dpc_fontDark};
            font-size: 2.6em;
            margin: 1em auto;
            text-align: center;
            border-bottom: .1em solid #EE4640;
            padding-bottom: .3em;
        }

        .step {
            &.step-survey {
                .component-public-online-survey {
                    padding: 1em 0 2em 0;
                }
            }
        }

        @media (max-width: ${variables.tabletL}) {}
        @media (max-width: ${variables.mobileL}) {}
    `;

