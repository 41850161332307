import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .form-error-message-component form {
            max-width: 40em;
        }

        .col-question {
            width: 60%;

            .question {
                .question-title {
                    color: ${variables.dpc_fontDark};
                    font-size: 3em;
                    font-weight: 900;
                }

                .question-subtitle {
                    color: ${variables.dpc_fontDark};
                    font-size: 1.3em;
                    font-weight: 300;
                    margin-bottom: 1.5em;
                }

                .question-options {
                    .question-option {
                        font-size: 1.3em;
                        margin-bottom: 1em;
                        cursor: pointer;
                        padding: 1em 2em;
                        color: ${variables.dpc_fontDark};
                        font-weight: 900;
                        width: 100%;
                        transition: .3s all ease-in-out;

                        &:hover,
                        &.question-option-selected {
                            background-color: #E3E3E3;
                        }
                    }
                }

                .question-form {
                    max-width: 50em;
                    margin-right: 5em;
                    margin-top: 3em;

                    .form-element {
                        margin-bottom: 1.7em;

                        &.input {
                            .input-label {
                                margin: 1em 0;

                                span {
                                    font-weight: 600;
                                    font-size: 1.4em;
                                    color: ${variables.dpc_fontDark};
                                }
                            }

                            .input-internal-wrapper {
                                background: transparent;

                                input {
                                    background: transparent;
                                    font-size: 1.2em;
                                    color: ${variables.dpc_fontDark};
                                }
                            }
                        }

                        &.checkbox {
                            .trigger {
                                background-color: transparent;
                                border: 2px solid #CCC;
                                width: 1.5em;
                                height: 1.5em;
                            }

                            input[value="true"]+.trigger {
                                &:after {
                                    background: #ED443D;
                                }
                            }

                            .label {
                                color: #444444;
                                font-size: 1em;

                                a {
                                    color: #666666;
                                }
                            }
                        }
                    }

                    .component-button {
                        margin-bottom: 2em;
                    }
                }
            }
        }

        .col-results {
            width: 40%;
            max-width: 40em;

            .results {
                background: ${variables.dpc_backgroundDarkLight};
                border-radius: .3em;
                padding: 3em;

                .question-result {
                    margin-bottom: 2em;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .question-index {
                        background-color: transparent;
                        text-align: center;
                        color: white;
                        width: 3em;
                        height: 3em;
                        border-radius: 360em;
                        border: 2px solid white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: .3s all ease-in-out;

                        .question-number {
                            font-size: 1.3em;
                        }
                    }

                    .question-result-content {
                        margin-left: 2em;

                        .question-title {
                            color: ${variables.dpc_fontDark};
                            font-size: 1.3em;
                            font-weight: 900;
                        }

                        .question-value {
                            color: ${variables.dpc_fontDark};
                            font-size: 1.1em;
                            font-weight: 300;
                        }
                    }

                    &.active {
                        .question-index {
                            border: 2px solid #EE4640;
                            background-color: transparent;
                            color: #EE4640;
                        }

                        .question-result-content {
                            .question-title {
                                color: #EE4640;
                            }
                        }
                    }

                    &.resolved {
                        .question-index {
                            background-color: ${variables.dpc_backgroundBlack};
                            color: ${variables.dpc_fontWhite};
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletL}) {
            flex-direction: column;

            .col-question {
                width: 100%;
                max-width: 100%;

                .question {
                    .question-title {
                        font-size: 2.4em;
                    }
                    .question-form {
                        max-width: 100%;
                        margin-right: 0;
                    }
                }
            }

            .col-results {
                width: 100%;
                margin-top: 5em;
                max-width: 100%;
            }
        }
        @media (max-width: ${variables.mobileL}) {}
    `;

