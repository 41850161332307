import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { LEAD_SOURCE_SURVEY_ONLINE, LEAD_MEDIUM_LP } from 'Consts/leads';

import {
    EVENT_ONLINE_SURVEY_STEP,
    EVENT_ONLINE_SURVEY_SUBMIT,
} from 'Consts/events';

import { trackEvent } from 'Services/Tracking';
import Logger from 'Services/Logger';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Input from 'Components/forms/Input';
import Checkbox from 'Components/forms/Checkbox';
import Button from 'Components/layout/Button';

export default class PublicOnlineWelcome extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            createLead: PropTypes.func.isRequired,
        }).isRequired,
        onFinish: PropTypes.func.isRequired,
    };
    static defaultProps = {
        values: {},
    };
    state = {
        currentQuestionName: null,
        values: {
            trainingTarget: null,
            previousExperience: null,
            physicalActivityLevel: null,
            trainingFrequency: null,
            nutrition: null,
            expectedSupport: null,
            name: null,
            surname: null,
            email: null,
            phone: null,
            selectAll: false,
            consentsMarketingPhone: false,
            consentsMarketingEmail: false,
            consentsMarketingTerms: false,
        },
        isPending: false,
        globalError: null,
        errors: {},
        questions: [{
            name: 'previousExperience',
            displayName: 'Doświadczenie treningowe',
            valuePattern: '{{previousExperience}}',
            title: 'Jak oceniasz swoje doświadczenie treningowe?',
            type: 'select',
            options: [{
                label: 'Niewielkie',
                value: 'Niewielkie',
            }, {
                label: 'Przeciętne',
                value: 'Przeciętne',
            }, {
                label: 'Duże',
                value: 'Duże',
            }],
        }, {
            name: 'trainingTarget',
            displayName: 'Cel treningowy',
            valuePattern: '{{trainingTarget}}',
            title: 'Jaki jest Twój główny cel treningowy?',
            type: 'select',
            options: [{
                label: 'Chcę schudnąć',
                value: 'Chcę schudnąć',
            }, {
                label: 'Chcę poprawić sylwetkę',
                value: 'Chcę poprawić sylwetkę',
            }, {
                label: 'Chcę pozbyć się dolegliwości bólowych',
                value: 'Chcę pozbyć się dolegliwości bólowych',
            }, {
                label: 'Chcę wrócić do aktywności fizycznej',
                value: 'Chcę wrócić do aktywności fizycznej',
            }, {
                label: 'Chcę zwiększyć siłę i masę mięśniową',
                value: 'Chcę zwiększyć siłę i masę mięśniową',
            }, {
                label: 'Chcę poprawić wydolność i wytrzymałość',
                value: 'Chcę poprawić wydolność i wytrzymałość',
            }, {
                label: 'Chcę poprawić wyniki sportowe',
                value: 'Chcę poprawić wyniki sportowe',
            }],
        }, {
            name: 'physicalActivityLevel',
            displayName: 'Poziom aktywności fizycznej',
            valuePattern: '{{physicalActivityLevel}}',
            title: 'Poziom aktywności fizycznej',
            subtitle: 'Ile razy ćwiczyłeś w zeszym tygodniu (dowolny trening: w domu, na dworze, na siłowni)?',
            type: 'select',
            options: [{
                label: '0-1 dzień',
                value: '0-1 dzień',
            }, {
                label: '2 dni',
                value: '2 dni',
            }, {
                label: '3-4 dni',
                value: '3-4 dni',
            }, {
                label: 'powyżej 4 dni',
                value: 'powyżej 4 dni',
            }],
        }, {
            name: 'trainingFrequency',
            displayName: 'Ilośc treningów',
            valuePattern: '{{trainingFrequency}}',
            title: 'Ile dni w tygodniu możesz przeznaczyć na treningi trenując z FitAdept?',
            type: 'select',
            options: [{
                label: '1 dzień w tygodniu',
                value: '1 dzień w tygodniu',
            }, {
                label: '2 dni w tygodniu',
                value: '2 dni w tygodniu',
            }, {
                label: '3-4 dni w tygodniu',
                value: '3-4 dni w tygodniu',
            }, {
                label: 'Powyżej 4 dni w tygodniu',
                value: 'Powyżej 4 dni w tygodniu',
            }],
        }, {
            name: 'nutrition',
            displayName: 'Żywienie',
            valuePattern: '{{nutrition}}',
            title: 'Żywienie',
            subtitle: 'Która z poniższych pozycji najlepiej opisuje Twoją obecną dietę?',
            type: 'select',
            options: [{
                label: 'Zbilansowana',
                value: 'Zbilansowana',
            }, {
                label: 'Wegetariańska / wegańska',
                value: 'Wegetariańska / wegańska',
            }, {
                label: 'Paleo / keto',
                value: 'Paleo / keto',
            }, {
                label: 'Mam katering dietetyczny',
                value: 'Mam katering dietetyczny',
            }, {
                label: 'Potrzebuję wsparcia w zakresie żywienia',
                value: 'Potrzebuję wsparcia w zakresie żywienia',
            }],
        }, {
            name: 'expectedSupport',
            displayName: 'Oczekiwane wsparcie',
            valuePattern: '{{expectedSupport}}',
            title: 'Jakiego wsparcia najbardziej oczekujesz?',
            type: 'select',
            options: [{
                label: 'Motywowania',
                value: 'Motywowania',
            }, {
                label: 'Nadzoru, pomocy w doborze ćwiczeń',
                value: 'Nadzoru, pomocy w doborze ćwiczeń',
            }, {
                label: 'Dobrej zabawy podczas ćwiczeń',
                value: 'Dobrej zabawy podczas ćwiczeń',
            }, {
                label: 'Nadzoru, pomocy w doborze żywienia',
                value: 'Nadzoru, pomocy w doborze żywienia',
            }],
        }, {
            name: 'nameAndSurname',
            displayName: 'Imię i nazwisko',
            valuePattern: '{{name}} {{surname}}',
            title: 'Imię i nazwisko',
            type: 'component',
            component: (
                <>
                    <Input
                        label="Imię"
                        name="name"
                        placeholder="Imię"
                        type="text"
                        required={true}
                        onChange={({ name, value }) => this.setQuestionValue(name, value)}
                    />
                    <Input
                        label="Nazwisko"
                        name="surname"
                        placeholder="Nazwisko"
                        type="text"
                        required={true}
                        onChange={({ name, value }) => this.setQuestionValue(name, value)}
                    />
                    <Button
                        className="submit-button"
                        onClick={() => {
                            const { values } = this.state;
                            let errors = {};

                            if (!values.name) {
                                errors.name = ['Pole jest wymagane'];
                            }

                            if (!values.name) {
                                errors.surname = ['Pole jest wymagane'];
                            }

                            if (Object.keys(errors).length) {
                                return this.setState({
                                    errors,
                                });
                            }

                            this.setNextCurrentQuestion();
                        }}
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        size="extraLarge"
                        styleVersion={2}
                        type="button"
                    >
                        Dalej
                    </Button>
                </>
            ),
        }, {
            name: 'contactInformation',
            displayName: 'Dane kontaktowe',
            valuePattern: '',
            title: 'Dane kontaktowe',
            type: 'component',
            component: (
                <>
                    <Input
                        label="Adres e-mail"
                        name="email"
                        placeholder="Adres e-mail"
                        type="email"
                        required={true}
                        onChange={({ name, value }) => this.setQuestionValue(name, value)}
                    />
                    <Input
                        label="Numer telefonu"
                        name="phone"
                        placeholder="Numer telefonu"
                        type="tel"
                        required={true}
                        onChange={({ name, value }) => this.setQuestionValue(name, value)}
                    />
                    <Button
                        className="submit-button"
                        type="submit"
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        size="extraLarge"
                        styleVersion={2}
                    >
                        Dalej
                    </Button>

                    <Checkbox
                        name="selectAll"
                        label="Zaznacz wszystkie zgody"
                        className="select-all-checkbox"
                    />
                    <Checkbox
                        name="consentsMarketingPhone"
                        label="Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu."
                    />
                    <Checkbox
                        name="consentsMarketingEmail"
                        label="Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną."
                    />
                    <Checkbox
                        name="consentsMarketingTerms"
                        label="Oświadczam, że zapoznałem/-am się z <a href='https://fitadept.com/regulamin' target='_blank'>Regulaminem</a> serwisu internetowego FitAdept.com oraz akceptuję jego treść."
                        required={true}
                    />
                </>
            ),
        }],
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.values.selectAll && this.state.values.selectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    consentsMarketingPhone: true,
                    consentsMarketingEmail: true,
                    consentsMarketingTerms: true,
                },
            }));
        }

        if (prevState.values.selectAll && !this.state.values.selectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    consentsMarketingPhone: false,
                    consentsMarketingEmail: false,
                    consentsMarketingTerms: false,
                },
            }));
        }
    }

    renderQuestion = question => {
        return (
            <div
                className={classnames(
                    'question',
                    `question-${question.name}`
                )}
            >
                <h2 className="question-title">{question.title}</h2>
                {question.subtitle && <h3 className="question-subtitle">{question.subtitle}</h3>}
                <div className="question-form">
                    {this.renderQuestionForm(question)}
                </div>
            </div>
        );
    }

    renderQuestionForm = ({ name, type, options, value, component }) => {
        switch (type) {
            case 'select':
                return (
                    <div
                        className={classnames(
                            'question',
                            `question-${name}`
                        )}
                    >
                        <div className="question-options">
                            {options.map(option => (
                                <div
                                    key={option.value}
                                    className={classnames({
                                        'question-option': true,
                                        'question-option-selected': value === option.value,
                                    })}
                                    onClick={() => {
                                        this.setQuestionValue(name, option.value);
                                        this.setNextCurrentQuestion();
                                    }}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case 'component':
                return (
                    <div
                        className={classnames(
                            'question',
                            `question-${name}`
                        )}
                    >
                        {component}
                    </div>
                );
        }
    }

    renderCurrentQuestion = () => {
        const { currentQuestionName, questions } = this.state;

        let currentQuestion = questions.find(({ name }) => name === currentQuestionName);
        if (!currentQuestion) {
            currentQuestion = questions[0];
        }

        return this.renderQuestion(currentQuestion);
    }

    renderQuestionResult = ({ name, displayName, valuePattern }, index) => {
        const { values, currentQuestionName, questions } = this.state;

        let questionIndex = 0;
        if (currentQuestionName) {
            questionIndex = questions
                .findIndex(({ name }) => name == currentQuestionName);
        }

        let matches = valuePattern.match(/\{\{(.*?)\}\}/g) || [];
        matches = matches.map(match => {
            let matchValue = match.match(/\{\{(.*?)\}\}/);
            return matchValue.input.replace(matchValue[0], values[matchValue[1]] || '');
        });

        return (
            <div
                key={name}
                className={classnames({
                    'question-result': true,
                    active: questionIndex === index,
                    resolved: questionIndex > index,
                })}
            >
                <div className="question-index">
                    <span className="question-number">
                        {index + 1}
                    </span>
                </div>
                <div className="question-result-content">
                    <h4 className="question-title">
                        {displayName}
                    </h4>
                    <h5 className="question-value">
                        {matches.join(' ')}
                    </h5>
                </div>
            </div>
        );
    }

    setNextCurrentQuestion = () => {
        const { currentQuestionName, questions } = this.state;

        let questionIndex = 0;
        if (currentQuestionName) {
            questionIndex = questions
                .findIndex(({ name }) => name == currentQuestionName);
        }

        let nextQuestion = null;
        if (typeof questions[questionIndex + 1] !== 'undefined') {
            nextQuestion = questions[questionIndex + 1];
        }

        trackEvent(EVENT_ONLINE_SURVEY_STEP, { stepName: nextQuestion && nextQuestion.name });
        this.setCurrentQuestion(nextQuestion && nextQuestion.name || null);
    }

    setCurrentQuestion = name => {
        const { questions } = this.state;

        const question = questions
            .find(question => question.name == name);

        this.setState({
            currentQuestionName: question && question.name || null,
        });
    }

    setQuestionValue = (name, value) => {
        this.setState(({ values }) => ({
            values: {
                ...values,
                [name]: value,
            },
        }));
    }

    onFinish = response => {
        const { onFinish } = this.props;
        const { values } = this.state;

        trackEvent(EVENT_ONLINE_SURVEY_SUBMIT, { leadId: response.payload.leadId });
        return onFinish(values, response);
    }

    onSubmit = () => {
        const { values } = this.state;
        const { actions, location } = this.props;

        const queryObject = parseQueryToObject(location.search);

        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        const consents = [{
            type: 'terms',
            content: 'Oświadczam, że zapoznałem/-am się z Regulaminem serwisu internetowego FitAdept.com oraz akceptuję jego treść.',
            value: values.consentsMarketingTerms,
        }, {
            type: 'marketingEmail',
            content: 'Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną.',
            value: values.consentsMarketingEmail,
        }, {
            type: 'phone',
            content: 'Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu.',
            value: values.consentsMarketingPhone
        }];
        
        return actions.createLead({
            ...values,
            consents,
            medium: LEAD_MEDIUM_LP,
            source: LEAD_SOURCE_SURVEY_ONLINE,
            withSurvey: true,
        })
            .then(response => {
                return this.onFinish(response);
            })
            .catch(error => {
                Logger.error('[ONLINE_CHECKOUT] Error', error);
                this.setState({
                    isPending: false,
                    errors: error.payload.validationErrors,
                    globalError: error.payload.message,
                });
            });
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        values: {
            ...prevState.values,
            [name]: value,
        },
    }))

    render() {
        const { questions, values, errors, globalError, isPending } = this.state;

        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-survey': true,
                })}
                styles={require('./styles')}
            >
                <div className="col-question">
                    <Form
                        data={values}
                        onStateChange={this.onStateChange}
                        errors={errors}
                        globalError={globalError}
                        onSubmit={this.onSubmit}
                        isPending={isPending}
                    >
                        {this.renderCurrentQuestion()}
                    </Form>
                </div>
                <div className="col-results">
                    <div className="results">
                        {questions.map((question, index) => this.renderQuestionResult(question, index))}
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
