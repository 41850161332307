import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { COOKIE_LEAD_ID } from 'Consts/cookies';
import { LOCAL_STORAGE_LEAD_SURVEY } from 'Consts/localStorage';

import { getProvider } from 'Services/Cookies';
import { setItem } from 'Services/LocalStorage';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Survey from 'Components/public/online/Survey';
import Confirmation from 'Components/public/online/Confirmation';

export default class PublicReservations extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};
    state = {
        values: null,
        response: null,
        step: 'survey',
    }

    saveData = (values, response) => {
        this.setState({
            step: 'confirmation',
            values,
            response,
        }, () => {
            getProvider().set(COOKIE_LEAD_ID, response.payload.leadId);
            setItem(
                LOCAL_STORAGE_LEAD_SURVEY,
                {
                    ...values,
                    ...response.payload,
                }
            );
        });
    }

    render() {
        const { location, history } = this.props;
        const { step, response } = this.state;

        return (
            <StyledComponent
                className={classnames({
                    'component-lp-online-survey': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                {step === 'survey' && (
                    <div className="step step-survey">
                        <LayoutContainer>
                            <div className="survey-header">
                                Wypełnienie ankiety zajmie Ci tylko 2 minuty
                            </div>
                            <Survey
                                onFinish={this.saveData}
                                location={location}
                                history={history}
                            />
                        </LayoutContainer>
                    </div>
                )}
                {step === 'confirmation' && (
                    <div className="step step-confirmation">
                        <LayoutContainer>
                            <Confirmation
                                location={location}
                                history={history}
                                response={response}
                            />
                        </LayoutContainer>
                    </div>
                )}
            </StyledComponent>
        );
    }
}
